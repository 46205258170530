import { api } from '@/api';
import { createAppAsyncThunk } from '@/store/createAppAsyncThunk';

export const getRegions = createAppAsyncThunk('regions/getRegions', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await api.getRegions();
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getCapturedRegions = createAppAsyncThunk('regions/getCapturedRegions', async (_, thunkApi) => {
  try {
    const {
      data: { data },
    } = await api.getRegionsCaptured(thunkApi.signal);
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const createRegion = createAppAsyncThunk(
  'regions/create',
  async (payload: Paths.RegionsCreate.Post.Parameters.Body, thunkApi) => {
    try {
      const { data } = await api.createRegion(payload);
      thunkApi.dispatch(getRegions());
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRegion = createAppAsyncThunk(
  'regions/delete',
  async (payload: Paths.RegionsDelete$RegionId.Post.PathParameters, thunkApi) => {
    try {
      const { data } = await api.deleteRegion(payload);
      thunkApi.dispatch(getRegions());
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAutomoderatedRegions = createAppAsyncThunk(
  'regions/getAutomoderatedRegions',
  async (payload: Paths.RegionsAutomoderate.Get.QueryParameters, thunkApi) => {
    try {
      const {
        data: { data },
      } = await api.getAutomoderateRegions(payload, thunkApi.signal);
      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createAutomoderatedRegion = createAppAsyncThunk(
  'regions/createAutomoderatedRegion',
  async (payload: Paths.RegionsAutomoderateCreate.Post.BodyParameters, thunkApi) => {
    try {
      const { data } = await api.createAutomoderateRegions(payload, thunkApi.signal);

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAutomoderatedRegion = createAppAsyncThunk(
  'regions/updateAutomoderatedRegion',
  async (
    payload: Paths.RegionsAutomoderateUpdate$RegionId.Post.BodyParameters &
      Paths.RegionsAutomoderateUpdate$RegionId.Post.PathParameters,
    thunkApi
  ) => {
    try {
      const { data } = await api.updateAutomoderateRegions(payload, thunkApi.signal);

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAutomoderatedRegion = createAppAsyncThunk(
  'regions/deleteAutomoderatedRegion',
  async (payload: Paths.RegionsAutomoderateDelete$RegionId.Post.PathParameters, thunkApi) => {
    try {
      const { data } = await api.deleteAutomoderateRegion(payload, thunkApi.signal);

      return data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
