import { createSlice } from '@reduxjs/toolkit';

import { State } from './types';
import {
  createRegion,
  deleteRegion,
  getRegions,
  getCapturedRegions,
  getAutomoderatedRegions,
  createAutomoderatedRegion,
  updateAutomoderatedRegion,
  deleteAutomoderatedRegion,
} from './thunks';
import { CAPTURED_REGION_STATUS } from './constants';

const initialState: State = {
  isLoading: false,
  items: [],
  captured: [],
  automoderated: [],
  myAutomoderated: [],
};

const slice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getRegions.fulfilled, (state, action) => {
      state.items = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getCapturedRegions.fulfilled, (state, action) => {
      if (action.payload)
        state.captured = action.payload.filter((region) =>
          CAPTURED_REGION_STATUS.includes(String(region.name).toLocaleLowerCase())
        );
    });

    builder.addCase(getAutomoderatedRegions.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.meta.arg.owned) {
          state.myAutomoderated = action.payload;
        } else {
          state.automoderated = action.payload;
        }
      }
    });

    builder.addCase(deleteAutomoderatedRegion.fulfilled, (state, action) => {
      state.automoderated = state.automoderated.filter((r) => r.id !== action.meta.arg.region_id);
      state.myAutomoderated = state.myAutomoderated.filter((r) => r.id !== action.meta.arg.region_id);
    });
  },
});

export const actions = {
  ...slice.actions,
  getRegions,
  deleteRegion,
  createRegion,
  getCapturedRegions,
  getAutomoderatedRegions,
  createAutomoderatedRegion,
  updateAutomoderatedRegion,
  deleteAutomoderatedRegion,
};

export default slice.reducer;
