import { AxiosPromise, AxiosRequestConfig } from 'axios';

import axios from '@/services/axios';

const AUTH_URL = '/auth';
const POINTS_URL = '/points';
const REGION_URL = '/regions';

type DisableToast = {
  disableToast?: boolean;
};

export type AxiosConfigWithoutToasts = AxiosRequestConfig & DisableToast;

class Api {
  login(data: Paths.AuthGoogleLoginV2.Post.Parameters.Body): AxiosPromise<Responses.AuthGoogleLoginV2> {
    return axios({
      url: `${AUTH_URL}/google_login_v2/`,
      method: 'POST',
      data,
    });
  }

  logout(): AxiosPromise {
    return axios({
      url: `${AUTH_URL}/logout/`,
      method: 'GET',
    });
  }

  getUser({
    user_id,
    disableToast,
  }: Paths.AuthUsers$UserId.Get.PathParameters & DisableToast): AxiosPromise<Responses.AuthUserIdGet> {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/ `,
      method: 'GET',
      disableToast,
    } as AxiosConfigWithoutToasts);
  }

  getUsers(params: Paths.AuthUsersList.Get.QueryParameters): AxiosPromise<Responses.AuthUsersList> {
    return axios({
      url: `${AUTH_URL}/users/list/`,
      method: 'GET',
      params,
    });
  }

  getDevicesManager(params: Paths.AuthUsersList.Get.QueryParameters): AxiosPromise<Responses.AuthUsersList> {
    return axios({
      url: `${AUTH_URL}/users/list-device-managers/`,
      method: 'GET',
      params,
    });
  }

  editUserById({
    user_id,
    ...data
  }: Paths.AuthUsers$UserId.Post.PathParameters &
    Paths.AuthUsers$UserId.Post.BodyParameters): AxiosPromise<Responses.EditUserById> {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/ `,
      method: 'POST',
      data,
    });
  }

  getDevices(params: Paths.AuthDevicesList.Get.QueryParameters): AxiosPromise<Responses.AuthDevicesList> {
    return axios({
      url: `${AUTH_URL}/devices/list/`,
      method: 'GET',
      params,
    });
  }

  getRoles(): AxiosPromise<Responses.AuthRolesList> {
    return axios({
      url: `${AUTH_URL}/roles/`,
      method: 'GET',
      disableToast: true,
    } as AxiosConfigWithoutToasts);
  }

  getInvites(params: Paths.AuthInvitesList.Get.QueryParameters): AxiosPromise<Types.InvitesData> {
    return axios({
      url: `${AUTH_URL}/invites/list/`,
      method: 'GET',
      params,
    });
  }

  generateInvites(data: Paths.AuthInvitesGenerate.Post.BodyParameters): AxiosPromise<Responses.GenerateInvites> {
    return axios({
      url: `${AUTH_URL}/invites/generate/`,
      method: 'POST',
      data,
    });
  }

  getPointsRealtimeMap(
    params: Paths.PointsRealtimeMap.Get.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.PointsRealtimeMap.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/realtime-map/`,
      method: 'GET',
      params,
      signal,
    });
  }

  getDevicesRealtimeMap(
    params: Paths.PointsRealtimeHeartbeats.Get.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.PointsRealtimeHeartbeats.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/realtime-heartbeats/`,
      method: 'GET',
      params,
      signal,
    });
  }

  getPointsDemo(
    params: Paths.PointsRealtimeMap.Get.QueryParameters
  ): AxiosPromise<Paths.PointsRealtimeMap.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/realtime-map-demo/`,
      method: 'GET',
      params,
    });
  }

  getRegions(): AxiosPromise<Responses.RegionsList> {
    return axios({
      url: `${REGION_URL}/`,
      method: 'GET',
    });
  }

  createRegion(data: Paths.RegionsCreate.Post.Parameters.Body): AxiosPromise<Paths.RegionsCreate.Post.Responses.$200> {
    return axios({
      url: `${REGION_URL}/create/`,
      method: 'POST',
      data,
    });
  }

  deleteRegion({
    region_id,
  }: Paths.RegionsDelete$RegionId.Post.PathParameters): AxiosPromise<Paths.RegionsDelete$RegionId.Post.Responses.$200> {
    return axios({
      url: `${REGION_URL}/delete/${region_id}/`,
      method: 'POST',
    });
  }

  getDeviceReports({
    user_id,
    ...params
  }: Paths.AuthDevices$UserIdReports.Get.PathParameters &
    Paths.AuthDevices$UserIdReports.Get.QueryParameters): AxiosPromise<Responses.DeviceReportsList> {
    return axios({
      url: `${AUTH_URL}/devices/${user_id}/reports/`,
      method: 'GET',
      params,
    });
  }

  getDeviceHeartbeats({
    user_id,
    ...params
  }: Paths.AuthDevices$UserIdHeartbeats.Get.PathParameters &
    Paths.AuthDevices$UserIdHeartbeats.Get.QueryParameters): AxiosPromise<Responses.DeviceHeartbeatsList> {
    return axios({
      url: `${AUTH_URL}/devices/${user_id}/heartbeats/`,
      method: 'GET',
      params,
    });
  }

  getDeviceUserAgents({
    user_id,
    ...params
  }: Paths.AuthDevices$UserIdUserAgents.Get.PathParameters &
    Paths.AuthDevices$UserIdUserAgents.Get.QueryParameters): AxiosPromise<Responses.DeviceUserAgentsList> {
    return axios({
      url: `${AUTH_URL}/devices/${user_id}/user-agents/`,
      method: 'GET',
      params,
    });
  }

  getUserManagedDevices({
    user_id,
    ...params
  }: Paths.AuthUsers$UserIdManagedDevices.Get.PathParameters &
    Paths.AuthUsers$UserIdManagedDevices.Get.QueryParameters): AxiosPromise<Responses.UserManagedDevicesList> {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/managed-devices/`,
      method: 'GET',
      params,
    });
  }

  addUserManagedDevices({
    user_id,
    ...data
  }: Paths.AuthUsers$UserIdAddManagedDevices.Post.PathParameters &
    Paths.AuthUsers$UserIdAddManagedDevices.Post.BodyParameters): AxiosPromise {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/add-managed-devices/`,
      method: 'POST',
      data,
    });
  }

  deleteUserManagedDevices({
    user_id,
    ...data
  }: Paths.AuthUsers$UserIdDeleteManagedDevices.Post.PathParameters &
    Paths.AuthUsers$UserIdDeleteManagedDevices.Post.BodyParameters): AxiosPromise {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/delete-managed-devices/`,
      method: 'POST',
      data,
    });
  }

  getUserInvited({
    user_id,
    ...params
  }: Paths.AuthUsers$UserIdInvited.Get.PathParameters &
    Paths.AuthUsers$UserIdInvited.Get.QueryParameters): AxiosPromise<Responses.UserInvitedList> {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/invited/`,
      method: 'GET',
      params,
    });
  }

  getUserRegions({
    user_id,
    ...params
  }: Paths.AuthUsers$UserIdRegions.Get.PathParameters &
    Paths.AuthUsers$UserIdRegions.Get.QueryParameters): AxiosPromise<Responses.UserRegions> {
    return axios({
      url: `${AUTH_URL}/users/${user_id}/regions/`,
      method: 'GET',
      params,
    });
  }

  getAudio({
    report_id,
    ...params
  }: Paths.PointsAudioFile$ReportId.Get.PathParameters &
    Paths.PointsAudioFile$ReportId.Get.QueryParameters): AxiosPromise<Blob> {
    return axios({
      url: `${POINTS_URL}/audio-file/${report_id}/`,
      method: 'GET',
      params,
      responseType: 'blob',
    });
  }

  getSpectogram({ report_id }: Paths.PointsSpectrogram$ReportId.Get.PathParameters): AxiosPromise<Blob> {
    return axios({
      url: `${POINTS_URL}/spectrogram/${report_id}/`,
      method: 'GET',
      responseType: 'blob',
    });
  }

  checkAudio({
    report_id,
    ...params
  }: Paths.PointsCheckAudioFile$ReportId.Get.PathParameters &
    Paths.PointsCheckAudioFile$ReportId.Get.QueryParameters): AxiosPromise<Responses.CheckAudioFile> {
    return axios({
      url: `${POINTS_URL}/check-audio-file/${report_id}/`,
      method: 'GET',
      params,
    });
  }

  getPointsHeartbeat(
    params: Paths.PointsHeartbeatMap.Get.QueryParameters,
    signal: AbortSignal
  ): AxiosPromise<Paths.PointsHeartbeatMap.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/heartbeat-map/`,
      method: 'GET',
      params,
      signal,
    });
  }

  getPointsHcEventsList(signal: AbortSignal): AxiosPromise<Paths.PointsHcEventsList.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/hc_events_list/`,
      method: 'GET',
      signal,
    });
  }

  pointModeration({
    report_id,
    ...data
  }: Paths.PointsModeration$ReportId.Post.BodyParameters &
    // eslint-disable-next-line max-len
    Paths.PointsModeration$ReportId.Post.PathParameters): AxiosPromise<Paths.PointsModeration$ReportId.Post.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/moderation/${report_id}/`,
      method: 'POST',
      data,
    });
  }

  sendPointForModeration({
    report_id, // eslint-disable-next-line max-len
  }: Paths.PointsSendForModeration$ReportId.Post.PathParameters): AxiosPromise<Paths.PointsSendForModeration$ReportId.Post.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/send-for-moderation/${report_id}/`,
      method: 'POST',
    });
  }

  trackModeration({
    track_label, // eslint-disable-next-line max-len
  }: Paths.PointsModerationEllipse$TrackLabel.Post.PathParameters): AxiosPromise<Paths.PointsModerationEllipse$TrackLabel.Post.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/moderation/ellipse/${track_label}/`,
      method: 'POST',
    });
  }

  downloadDevicesCsv(params: Payloads.DownloadCSV): AxiosPromise<BlobPart> {
    return axios({
      url: `${POINTS_URL}/download-devices-csv/`,
      method: 'GET',
      responseType: 'arraybuffer',
      params,
    });
  }

  downloadDevicesGroupedCsv(params: Payloads.DownloadCSV): AxiosPromise<BlobPart> {
    return axios({
      url: `${POINTS_URL}/download-devices-grouped-csv/`,
      method: 'GET',
      responseType: 'arraybuffer',
      params,
    });
  }

  pointsTrajectoriesRecalculate(
    data: Paths.PointsTrajectoriesRecalculate.Post.Parameters.Body
  ): AxiosPromise<Paths.PointsTrajectoriesRecalculate.Post.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/trajectories/recalculate/`,
      method: 'POST',
      data,
    });
  }

  getReportById({
    report_id,
  }: Paths.PointsReport$ReportId.Get.PathParameters): AxiosPromise<Paths.PointsReport$ReportId.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/report/${report_id}/`,
      method: 'GET',
    });
  }

  getHeartbeatById({
    heartbeat_id, // eslint-disable-next-line max-len
  }: Paths.PointsHeartbeat$HeartbeatId.Get.PathParameters): AxiosPromise<Paths.PointsHeartbeat$HeartbeatId.Get.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/heartbeat/${heartbeat_id}/`,
      method: 'GET',
    });
  }

  setTrackStatus({
    track_label,
    ...data
  }: Paths.PointsTrackStatus$TrackLabel.Post.PathParameters &
    // eslint-disable-next-line max-len
    Paths.PointsTrackStatus$TrackLabel.Post.BodyParameters): AxiosPromise<Paths.PointsTrackStatus$TrackLabel.Post.Responses.$200> {
    return axios({
      url: `${POINTS_URL}/track/status/${track_label}/`,
      method: 'POST',
      data,
    });
  }

  getRegionsCaptured(signal: AbortSignal): AxiosPromise<Paths.RegionsCaptured.Get.Responses.$200> {
    return axios({
      url: `${REGION_URL}/captured/`,
      method: 'GET',
      signal,
    });
  }

  getAutomoderateRegions(
    params: Paths.RegionsAutomoderate.Get.QueryParameters,
    signal?: AbortSignal
  ): AxiosPromise<Responses.AutomoderatedRegionsList> {
    return axios({
      url: `${REGION_URL}/automoderate/`,
      method: 'GET',
      params,
      signal,
    });
  }

  createAutomoderateRegions(
    data: Paths.RegionsAutomoderateCreate.Post.BodyParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.RegionsAutomoderateCreate.Post.Responses.$200> {
    return axios({
      url: `${REGION_URL}/automoderate/create/`,
      method: 'POST',
      data,
      signal,
    });
  }

  updateAutomoderateRegions(
    {
      region_id,
      ...data
    }: Paths.RegionsAutomoderateUpdate$RegionId.Post.BodyParameters &
      Paths.RegionsAutomoderateUpdate$RegionId.Post.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.RegionsAutomoderateUpdate$RegionId.Post.Responses.$200> {
    return axios({
      url: `${REGION_URL}/automoderate/update/${region_id}/`,
      method: 'POST',
      data,
      signal,
    });
  }

  deleteAutomoderateRegion(
    { region_id }: Paths.RegionsAutomoderateDelete$RegionId.Post.PathParameters,
    signal?: AbortSignal
  ): AxiosPromise<Paths.RegionsAutomoderateDelete$RegionId.Post.Responses.$200> {
    return axios({
      url: `${REGION_URL}/automoderate/delete/${region_id}/`,
      method: 'POST',
      signal,
    });
  }
}

export const api = new Api();
