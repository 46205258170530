import { useCallback } from 'react';

import { useSelector, userSelectors } from '@/store';

export enum Role {
  ADMIN = 1,
  NETWORK = 2,
  SOUND_APP = 3,
  SOUND_MASTER = 4,
  MODERATOR = 5,
  SUB_MODERATOR = 6,
  REALTIME = 7,
  DEMO = 8,
  DEVICE = 9,
  COMMAND_POST = 10,
  BIRDWATCHER = 11,
  VERIFICATOR = 12,
}

export const usePermission = (roles?: Role[]) => {
  const currentUser = useSelector(userSelectors.getCurrent);

  const validateAccess = useCallback(
    (roles: Role[]) => !!currentUser && currentUser.roles.some(({ id }) => roles.includes(id)),
    [currentUser]
  );
  return {
    isValid: validateAccess(roles || []),
    validateAccess,
  };
};

export const useItsMe = (userId?: number) => {
  const currentUserId = useSelector(userSelectors.getCurrentId);

  return !!userId && currentUserId && currentUserId === userId;
};
